import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const HomePage = ({ pageContext: { description, title } }) => (
  <Layout isHomePage>
    <Seo title={title} />

    <h1 className="hero-text">{description}</h1>
  </Layout>
)

export default HomePage
